<template>
  <button v-on:click="buttonAction()" type="button" class="btn sam-secondary-button" :class="{'mb-4': !noSpace}"
          :disabled="disabled === true">{{ text }}
  </button>
</template>

<script>
export default {
  name: 'SamSecondaryButton',
  components: {},
  props: {
    //! Menu settings
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    noSpace: {
      type: Boolean,
      default: false,
    },
    toggleModal: {
      type: Boolean,
      default: false
    },
    modalId: {
      type: String,
      default: ''
    }
  },
  methods: {
    buttonAction(){
      this.$emit('buttonAction');
    },
  }
}
</script>

<style lang="scss" scoped>
.btn.sam-secondary-button, .btn.sam-secondary-button:focus {
  background: $lightGrey !important;
  color: $almostBlack;
  border: none;
  height: $regularButtonHeight;
  width: $regularButtonWidth;
  transition: $mainTransition;
  font-family: $boldFontStyle;
  border-radius: $mainRadius;
}

.sam-secondary-button:hover {
  opacity: $hoverOpacity;
}

.sam-secondary-button:disabled {
  opacity: $disableOpacity;
}
</style>
